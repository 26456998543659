import Vue, { PropType } from 'vue';
import IconBase from '../icon';
import { TdIconSVGProps, IconProps, SVGJson } from '../utils/types';
import useSizeProps from '../utils/use-size-props';

const element: SVGJson = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 16 16","width":"1em","height":"1em"},"children":[{"tag":"g","attrs":{"fill":"currentColor","opacity":0.9},"children":[{"tag":"path","attrs":{"d":"M8 9a2.78 2.78 0 100-5.56A2.78 2.78 0 008 9zm0-1a1.78 1.78 0 110-3.56A1.78 1.78 0 018 8z"}},{"tag":"path","attrs":{"d":"M8 15A7 7 0 108 1a7 7 0 000 14zm5-3.68A10.2 10.2 0 008 10c-1.79 0-3.47.48-5 1.32a6 6 0 1110 0zm-.64.8a5.98 5.98 0 01-8.72 0 9.17 9.17 0 018.72 0z"}}]}]};

const UserCircle = Vue.extend<IconProps>({
  name: 'UserCircleIcon',
  functional: true,
  props: {
    // small/medium/large/xl/18px/2em
    size: {
      type: String,
    },
    onClick: {
      type: Function as PropType<TdIconSVGProps['onClick']>,
    },
  },
  render(createElement, context) {
    const { props, data } = context;
    const { size, ...otherProps } = props;

    const { className, style } = useSizeProps(size);
    const fullProps = {
      ...otherProps || {},
      id: 'user-circle',
      icon: element,
      staticClass: className,
      style,
    };
    data.props = fullProps;
    return createElement(IconBase, data);
  },
});

export default UserCircle;
